/* VARIABLES
---------------------------------------------- */
:root {
  /* --vh: 1; */

  --font-size: 16px;
  --body-font: 'Tiempos Text', serif;
  --heading-font: 'Tiempos Headline', serif;
  --sans-serif-font: 'Calibre', sans-serif;


  /* --tiny-font-size: .85rem;
  --xx-small-font-size: 1.2rem;
  --x-small-font-size: 1.4rem;
  --small-font-size: 1.65rem; */
  --medium-font-size: clamp(1.3rem, 2.5vw, 1.5rem);
  --large-font-size: clamp(2rem, 3vw, 2.2rem);
  --x-large-font-size: clamp(2.5rem, 4.5vw, 3.5rem);
  --xx-large-font-size: clamp(2.8rem, 1.6rem + 3.2vw, 4.2rem);
  --xxx-large-font-size: clamp(3.2rem, 1.914rem + 3.429vw, 4.7rem);
  --huge-font-size: clamp(4.2rem, 2.5rem + 1.429vw, 5.7rem); /* Blog page titles */ 

  --xx-small-vertical-margin: .2rem;
  --x-small-vertical-margin: 1rem;
  --small-vertical-margin: 3rem;
  --medium-vertical-margin: 5rem;
  --large-vertical-margin: 9rem;
  --x-large-vertical-margin: 12rem;
  --xx-large-vertical-margin: 14rem;

  /* --x-small-width: 100px;
  --small-width: 300px;
  --medium-width: 400px;
  --large-width: 700px; */
  --x-large-width: 800px;
  --xx-large-width: 990px;



  /*--off-black: #3a3a3a;
  --off-black: #333333;
  --off-black: #1c1c1c;
  --off-black: #1a1a1a; */
  --black: #20201e;
  --white: white;
  /* --light-grey: #deddd9; */
  --grey: #e8e7e4; 
  --darkgrey: #8a8a8a;
  --cream: #E5E4E0;
  --dot-color: #e5e4e0;
  --green: rgba(63, 127, 101, 1);
  --blue: ;
  --purple: ;

  --page-width: 1300px;
  --lr-page-padding: 40px;
  --medium-grid-gap: clamp(1.8rem, 4.5vw, 4.5rem);
  --large-grid-gap: calc(var(--lr-page-padding)*3);

  /* Logos animation */
  --duration: 60s;
  --gap: 60px;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));

  font-family: var(--body-font);
  font-size: var(--font-size);
  color: var(--black);
}

/* MOBILE CSS VARIABLES
---------------------------------------------- */
@media (max-width: 599px) {
  :root {
  }
}

/* SETTING MEDIA QUERIES
---------------------------------------------- */

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 1024px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}