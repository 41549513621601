@import "fonts";
@import "reset";
@import "media-queries";
@import "variables";
@import "mixins";
@import "../node_modules/@splidejs/splide/dist/css/splide.min";


@media (hover: hover) {
  .button:hover {
  }
}

/* GLOBAL
---------------------------------------------- */
body {
  font-family: var(--body-font);
  color: var(--black);
  -webkit-font-smoothing: antialiased;
}
html {
  scroll-behavior: smooth;
}
/* .uniform__potty {
  position: absolute;
  left: -9999px;
} */
.visually-hidden {
  position:absolute !important;
  overflow:hidden;
  width:1px;
  height:1px;
  margin:-1px;
  padding:0;
  border:0;
  clip:rect(0 0 0 0);
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap:normal !important
}
.center {
  display: flex;
  justify-content: center!important;
  align-items: center!important;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
.text-align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.sans-serif {
  font-family: var(--sans-serif-font);
  letter-spacing: .05em;
  font-weight: 500;
}
h1 {
  font-family: var(--heading-font);
}
.tiny-font-size, .tiny-font-size p {
  font-size: var(--tiny-font-size);
}
.xx-small-font-size, .xx-small-font-size p {
  font-size: var(--xx-small-font-size);
}
.x-small-font-size, .x-small-font-size p {
  font-size: var(--x-small-font-size);
}
.small-font-size, .small-font-size p {
  font-size: var(--small-font-size);
}
.medium-font-size, .medium-font-size p {
  font-size: var(--medium-font-size);
}
.large-font-size, .large-font-size p {
  font-size: var(--large-font-size);
}
.x-large-font-size, .x-large-font-size p {
  font-size: var(--x-large-font-size);
}
.xx-large-font-size {
  font-size: var(--xx-large-font-size);
}
.underlined, .button a {
  text-decoration-color: var(--black);
  @include underlined-link;
}
.underlined-on-hover {
  a {
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-color: transparent;
    text-decoration-thickness: .06em;
    transition: text-decoration-color .4s ease;
    &:hover,
    &.active-link {
      text-decoration-color: var(--black);
    }
  }
}
.dark-background {
  .underlined,
  .button a {
    text-decoration-color: var(--white);
  }
  .underlined-on-hover {
    a {
      &:hover,
      &.active-link {
        text-decoration-color: var(--white);
      }
    }
  }
}
.page-width {
  @include page-width;
}
.x-large-width {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--x-large-width);
}
.lr-page-padding {
  padding-left: var(--lr-page-padding);
  padding-right: var(--lr-page-padding);
}
.x-small-margin-bottom {
  margin-bottom: var(--small-vertical-margin);
}
.small-margin-bottom {
  margin-bottom: var(--small-vertical-margin);
}
.medium-margin-bottom {
  margin-bottom: var(--medium-vertical-margin);
}
.large-margin-bottom {
  margin-bottom: var(--large-vertical-margin);
}
.x-large-margin-bottom {
  margin-bottom: var(--x-large-vertical-margin);
}
.xx-large-margin-bottom {
  margin-bottom: var(--x-large-vertical-margin);
}
.media-left {
  &>div:first-of-type {
    order: 0;
  }
} 
.media-right {
  &>div:first-of-type {
    order: 1;
  }
}

/* Lazy loading
---------------------------------------------- */

[data-lazy] {
  visibility: hidden;
}

.image-wrap {
  background-size: cover;
  min-width: 0;
}

/* Carousels
---------------------------------------------- */

.splide {
  visibility: inherit;
  &.is-initialized, &.is-rendered {
    visibility: inherit;
  }
  &__slide {
    align-self: start;
  }
  &__track {
    height: 100%;
  }
}

/* navbar
---------------------------------------------- */
.page-header {
  position: fixed;
  z-index: 1;
  width: 100%;
}
.navbar {
  @include page-width;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--lr-page-padding) calc(var(--lr-page-padding)/2) 0 var(--lr-page-padding);
  pointer-events: none;
  &__logo {
    pointer-events: initial;
    svg {
      fill: var(--black);
      height: 26px;
      width: auto
    }
  }
  &__menu {
    display: flex;
    pointer-events: initial;
  }
  &__item {
    &--square-link {
      margin: 0 calc(var(--lr-page-padding)/2);
      border: 1px solid var(--black);
      .navbar__link {
        padding: 8px 50px;
        transition: background-color .4s ease;
        color: var(--black);
        text-decoration: none;
        &:hover {
          background-color: var(--black);
          color: var(--white);
        }
      }
    }
  }
  &__link {
    display: block;
    padding: 8px calc(var(--lr-page-padding)/2);
    color: var(--black);
  }
  &.light-navbar {
    --black: white;
    --white: #20201e;
  }
  &.dark-navbar {
    --black: #20201e;
    --white: white; 
  }
}
/* footer
---------------------------------------------- */
.footer-container {
  background: var(--black);
  color: var(--white);
  padding: var(--large-vertical-margin) 0 calc(var(--lr-page-padding)*1);
  .footer-columns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--lr-page-padding);
    margin-bottom: var(--large-vertical-margin);
    a {
      padding: .2rem 0;
      display: block;
      font-size: var(--medium-font-size);
      color: inherit;
    }
  }
  .policies-links {
    margin-top: 2.5rem;
    ul {
      display: flex;
      justify-content: center;
      a,
      .copyright {
        display: block;
        padding: .5rem calc(var(--lr-page-padding)/3);
        color: inherit;
        font-size: var(--small-font-size);
      }
    }
    &.policies-links--increased-margins {
      margin-top: 2.6rem;
      margin-bottom: .5rem;
    }
  }
  .footer-email a {
    color: inherit;
    font-size: 1.2rem;
  }
}

/* square-button
---------------------------------------------- */
.square-button {
  display: flex;
  justify-content: center;
  font-family: var(--sans-serif-font);
  text-transform: uppercase;
  &__link {
    display: inline-block;
    padding: 12px 30px;
    transition: .4s ease;
    background-color: var(--grey);
    border: 1px solid var(--grey);
    text-decoration: none;
    color: inherit;
    outline: none;
    &:hover {
      background-color: transparent;
      border: 1px solid var(--black);
    }
    &:focus {
      outline: none;
    }
    &:focus-visible{
      outline: 1px solid var(--black);
      outline-offset: 1px;
    }
    &--white {
      background-color: var(--white);
      border: 1px solid var(--black);
      &:hover {
        background-color: var(--black);
        border: 1px solid var(--black);
        color: var(--white);
      }
    }
  }
}

/* preheading
---------------------------------------------- */
.preheading {
  margin-bottom: var(--small-vertical-margin);
  font-family: var(--sans-serif-font);
  font-size: 1.6rem;
  text-transform: uppercase;
  color: var(--darkgrey);
}

/* background
---------------------------------------------- */
.background-color {
  padding-top: var(--large-vertical-margin);
  padding-bottom: var(--medium-vertical-margin);
  &--cream {
    background-color: var(--cream);
  }
}

/* background-gradient
---------------------------------------------- */
.background-gradient {
  padding-top: var(--large-vertical-margin);
  padding-bottom: var(--medium-vertical-margin);
  &--cream {
    background: linear-gradient(180deg, rgba(229, 228, 224, 0) 0%, #E5E4E0 21.35%, rgba(223, 223, 221, 0.503759) 79.17%, rgba(229, 228, 224, 0) 100%);
    .square-button__link--white {
      border-color: var(--white);
      &:hover {
        border-color: var(--black);
      }
    }
  }
}

/* blog-container 
---------------------------------------------- */
.blog-container {
  margin-bottom: var(--small-vertical-margin);
}

/* blog-banner
---------------------------------------------- */
.blog-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding-top: 28vh;
  margin-bottom: var(--large-vertical-margin);
  &__title-container {
  }
  &__title {
    font-family: var(--heading-font);
    font-size: var(--huge-font-size);
  }
  &__description {
    font-size: var(--medium-font-size);
    margin-bottom: var(--small-vertical-margin);
  }
  &__category-links {
    display: flex;
    justify-content: space-between;
  }
  &__category-link {
    text-transform: uppercase;
    color: var(--grey);
    padding: .5rem 0;
    font-family: var(--sans-serif-font);
  }
}

/* latest-articles
---------------------------------------------- */
.latest-articles {
  padding-bottom: var(--large-vertical-margin);
  border-bottom: 1px solid var(--grey);
  &__heading-container {
    padding-bottom: var(--x-small-vertical-margin);
    border-bottom: 1px solid var(--grey);
  }
  &__heading {
    font-family: var(--heading-font);
    font-size: var(--x-large-font-size);
  }
}

/* cards
---------------------------------------------- */
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem 1.2rem;
  margin-top: var(--small-vertical-margin);
}
.card {
  &__image {
    aspect-ratio: 1 / 1.25;
    object-fit: cover;
  }
  &__theme {
    margin: 1.5rem 0 1.2rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  &__title-wrapper {
    text-decoration: none;
    color: var(--black);
  }
  &__title {
    font-size: var(--medium-font-size);
    margin-bottom: 1.2rem;
  }
  &__length {
    margin-top: 1.5rem;
    color: var(--grey);
    font-family: var(--sans-serif-font);
    text-transform: lowercase;
  }
}

/* featured-article
---------------------------------------------- */
.featured-article {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  padding-bottom: var(--medium-vertical-margin);
  border-bottom: 1px solid var(--grey);
  &__text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__heading {
    font-family: var(--heading-font);
    font-size: var(--huge-font-size);
  }
  &__theme {
    margin: 3rem 0 3rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  &__title-wrapper {
    text-decoration: none;
    color: var(--black);
  }
  &__title {
    font-size: var(--medium-font-size);
    margin-bottom: 1.2rem;
  }
  &__button {
    display: block;
    color: var(--black);
    margin-top: 1rem;
    padding: .2rem 0;
  }
  &__image {
    aspect-ratio: 1 / 1.25;
    object-fit: cover;
  }
}

/* blog-category-section
---------------------------------------------- */
.blog-category-section {
  margin-top: var(--medium-vertical-margin);
  border-bottom: 1px solid var(--grey);
  &__title-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
    margin-bottom: var(--large-vertical-margin);
  }
  &__title {
    font-family: var(--heading-font);
    font-size: var(--x-large-font-size);
  }
  &__description {
    font-size: var(--medium-font-size);
  }
  &__view-all-container {
    margin-top: var(--large-vertical-margin);
  }
  &__view-all-text {
    font-family: var(--sans-serif-font);
  }
  &__view-all-button {
    margin-left: 1rem;
    color: inherit;
    text-transform: uppercase;
    display: inline-block;
    padding: var(--x-small-vertical-margin);
  }
}

/* blog-filters
---------------------------------------------- */
.blog-filters {
  &__container {
    display: flex;
  }
  &__item:not(:last-of-type) {
    margin-right: 2.5rem;
  }
  &__link {
    color: var(--black);
    text-decoration: none;
    font-family: var(--sans-serif-font);
  }
}

/* blog-category-banner
---------------------------------------------- */
.blog-category-banner {
  padding-top: 28vh;
  &__title {
    font-family: var(--heading-font);
    font-size: var(--huge-font-size);
  }
  &__theme {
    font-size: .6em;
  }
  &__filters {
    margin-top: var(--large-vertical-margin);
  }
}
.blog-category-cards {
  margin-bottom: var(--large-vertical-margin);
}

/* article-banner
---------------------------------------------- */
.article-header {
  &--split {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  &__image {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }
  &__right {
    background-color: var(--grey);
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  &__banner-text-wrapper {
    padding: 2.5rem 3rem;
  }
  .article-theme {
    display: block;
    margin: 3rem 0 3rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    color: var(--black);
    text-decoration: none;
  }
  .article-title {
    font-size: var(--x-large-font-size);
  }
  .article-length {
    margin-top: 1.5rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
}

/* article-intro
---------------------------------------------- */
.article-intro {
  margin-top: var(--medium-vertical-margin);
  &__name {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    text-align: center;
    color: var(--grey);
  }
  &__date {
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    text-align: center;
  }
  &__excerpt {
    margin: var(--medium-vertical-margin) auto var(--small-vertical-margin);
    font-size: var(--medium-font-size);
    text-align: center;
    max-width: var(--xx-large-width);
  }
}

/* MODULES
----------------------------------------------
----------------------------------------------
---------------------------------------------- */

/* blog
---------------------------------------------- */

/* cta
---------------------------------------------- */
.cta {
  text-align: center;
  &__heading {
    margin-bottom: var(--small-vertical-margin);
    font-family: var(--heading-font);
    font-size: var(--x-large-font-size);
    padding: 0 2rem;
    @include for-phone-below {
      padding: 0 0;
    }
    &--x-large-width {
      max-width: var(--x-large-width);
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__paragraph {
    margin-bottom: var(--medium-vertical-margin);
    font-size: var(--medium-font-size);
    padding: 0 2rem;
    @include for-phone-below {
      padding: 0 0;
    }
    &--x-large-width {
      max-width: var(--x-large-width);
      margin-left: auto;
      margin-right: auto;
    }
    &.large-font-size {
      font-family: var(--heading-font);
    }
  }
}


/* circle-with-text
---------------------------------------------- */
.circle-with-text {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height: 30vw;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 51.56%, #E5E4E0 100%);
  }
  &__titles {
    text-align: center;
    font-family: var(--heading-font);
    font-size: var(--medium-font-size);
    font-style: italic;
  }
}
/* complex-hover
---------------------------------------------- */
.complex-hover {
  &__buttons-row {
    margin-bottom: var(--medium-vertical-margin);
  }
  &__tabs-row-wrap {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  &__tabs-column-a {
    grid-column: 1 / span 5;
  }
  &__tabs-column-b {
    grid-column: 7 / span 6;
    font-size: var(--medium-font-size);
    @include for-tablet-portrait-up {
      padding-top: 2rem;
    }
  }
}

/* Complex hover background color
---------------------------------------------- */
.complex-hover-background-color {
  transition: all 0.4s ease;
  &[data-bg="green"] {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(243, 247, 246, 1) 3.7%, rgba(189, 211, 202, 1) 22.18%, rgba(144, 181, 166, 1) 40.21%, rgba(109, 157, 138, 1) 57.39%, rgba(84, 141, 117, 1) 73.51%, rgba(68, 131, 105, 1) 88.16%, rgba(63, 127, 101, 1) 100%);
  }
  &[data-bg="blue"] {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(219, 235, 243, 1) 19.59%, rgba(185, 216, 231, 1) 42.57%, rgba(159, 202, 222, 1) 64.3%, rgba(144, 194, 217, 1) 84.03%, rgba(139, 191, 215, 1) 100%);
  }  
  &[data-bg="purple"] {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(225, 227, 236, 1) 25.74%, rgba(200, 203, 219, 1) 53.75%, rgba(185, 188, 209, 1) 79.32%, rgba(180, 183, 206, 1) 100%);
  }
  &[data-bg="green"],
  &[data-bg="blue"],
  &[data-bg="purple"] {
    --darkgrey: #20201e;
    & .left-aligned-large-paragraph__text,
    & .text-banner {
      color: var(--white)
    }
  }
}

/* hover-buttons */
.hover-buttons {
  &__item {
    &:not(:last-of-type) {
      margin-bottom: .8rem;
    }
    &--small-bottom-margin:not(:last-of-type) {
      margin-bottom: .4rem;
    }
  }
  &__heading {
    display: inline;
    font-family: var(--heading-font);
    font-size: var(--xxx-large-font-size);
    line-height: 1;
    transition: color .4s ease;
    color: var(--grey);
    text-align: left;
    text-decoration: underline;
    text-underline-position: intial;
    text-decoration-thickness: .06em;
    &--small {
      font-size: var(--large-font-size);
    }
    &:hover {
      cursor: default;
    }
    &.menu-active {
      color: var(--black);
    }
  }
}

/* hero
---------------------------------------------- */
.hero {
  &__wrap {
    position: relative;
  }
  &__image {
    width: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 75vh;
    }
    &--tall {
      img {
        height: 100vh;
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -45%);
    color: var(--white);
    .h1 {
      max-width: 900px;
      margin-left: 0;
      margin-right: 0;
      font-size: var(--xx-large-font-size);
    }
    p {
      margin-top: 2rem;
      max-width: var(--xx-large-width);
      font-size: var(--large-font-size);
    }
    &--tall {
      top: 52%;
      transform: translate(-50%, -48%);
      font-size: var(--xxx-large-font-size);
    }
  }
}

/* left-aligned-large-paragraph
---------------------------------------------- */
.left-aligned-large-paragraph {
  padding-right: 2rem;
  font-family: var(--heading-font);
  @include for-phone-below {
    padding-right: 0rem;
  }
  a {
    color: var(--darkgrey);
    font-style: italic;
    @include underlined-link;
  }
}

/* logos-animation
---------------------------------------------- */
.logo-animation-container{
  overflow: hidden;
}
.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
  /* &:not(:last-of-type) {
    margin-bottom: var(--medium-vertical-margin);
  } */
  &__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll-x var(--duration) linear infinite;
    img {
      height: 45px;
      width: auto;
    }
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

.marquee svg {
  display: grid;
  place-items: center;
  width: var(--size);
  fill: var(--color-text);
  background: var(--color-bg-accent);
  aspect-ratio: 16/9;
  padding: calc(var(--size) / 10);
  border-radius: 0.5rem;
}

.marquee--vertical svg {
  aspect-ratio: 1;
  width: calc(var(--size) / 1.5);
  padding: calc(var(--size) / 6);
}

.logo-animation-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: auto;
  max-width: 100vw;
}

/* one-column-image
---------------------------------------------- */
.one-column-image {
  img {
    height: 50vw;
    object-fit: cover;
    @include for-desktop-up {
      height: 700px;
    }
  }
}

/* partner-info
---------------------------------------------- */
.partner-info {
  &__name {
    margin-bottom: 1rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
    font-size: var(--small-font-size);
  }
  &__type,
  &__location {
    font-size: var(--x-small-font-size);
  }
  &__linkedin {
    margin-top: .7rem;
  }
}

/* scroll-hover-animation
---------------------------------------------- */
.scroll-hover-animation {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  &__column-a {
    grid-column: 1 / span 5;
  }
  &__column-b {
    grid-column: 7 / span 6;
    position: sticky;
    top: 60px;
    align-self: start;
  }
  &__heading {
    margin-bottom: var(--small-vertical-margin);
    font-family: var(--heading-font);
    font-size: var(--xxx-large-font-size);
    line-height: 1;
  }
  &__text {
    font-size: var(--medium-font-size);
  }
}

/* simple-hover
---------------------------------------------- */
.simple-hover {
  &__grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
  &__buttons-column {
    grid-column: 1 / span 5;
  }
  &__tabs-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    grid-column: 7 / span 6;
    align-self: stretch;
  }
  .simple-hover__text {
    font-size: var(--medium-font-size);
  }
  .square-button {
    justify-content: initial;
    margin-top: var(--medium-vertical-margin);
  }
}
/* simple-image-hover
---------------------------------------------- */
.simple-image-hover {
  .square-button {
    margin-top: var(--large-vertical-margin);
  }
}
/* square-button-fixed-link
---------------------------------------------- */

/* square-button-pages
---------------------------------------------- */

/* tabs
---------------------------------------------- */
.tab-body-content {
  display: none;
  opacity: 0;
  transition: opacity 1s;
  &.show-tab {
    opacity: 1;
    display: block;
    animation-name: example;
    animation-duration: 1.2s;
    animation-timing-function: ease;
  }
}

@keyframes example {
  from {opacity: 0;}
  to {opacity: 1;}
}

/* testimonial
---------------------------------------------- */
.testimonials {
  &__title {
    h2 {
      font-size: var(--xxx-large-font-size);
      color: var(--darkgrey);
      text-align: center;
    }
    .dots {
      margin-top: var(--small-vertical-margin);
      margin-bottom:  var(--small-vertical-margin);
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      div {
        height: 10px;
        width:10px;
        background-color: var(--white);
        border: 2px solid transparent;
        border-radius: 50%;
        &:first-of-type {
          background-color: var(--black);
        }
      }
    }
  }
  &__text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom:  var(--medium-vertical-margin);
    max-width: var(--x-large-width);
    font-size: var(--large-font-size);
    text-align: center;
  }
  .testimonials__circle-image {
    margin-bottom: var(--small-vertical-margin);
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 200px;
      height: auto;
      border-radius: 50%;
      filter: grayscale(100%);
    }
  }
  .partner-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
/* testimonials-with-media
---------------------------------------------- */
.testimonials-with-media {
  &__wrapper {
    padding-bottom: var(--medium-vertical-margin);
    border-bottom: 1px solid var(--grey);
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: calc(2*var(--lr-page-padding));
  }
  &__text-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      font-size: var(--xxx-large-font-size);
    }
    .dots {
      margin-top: var(--small-vertical-margin);
      display: flex;
      flex-direction: row;
      gap: 10px;
      div {
        height: 10px;
        width:10px;
        background-color: transparent;
        border: 2px solid var(--darkgrey);
        border-radius: 50%;
        &:first-of-type {
          background-color: var(--black);
          border: 2px solid var(--black);
        }
      }
    }
  }
  &__text {
    font-size: var(--large-font-size);
  }
}
/* text-banner
---------------------------------------------- */
.text-banner {
  padding-top: 28vh;
  &__inner {
    $self: &;
    max-width: var(--x-large-width);
    &#{ $self }--text-align-center {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    };
    .h1 {
      font-family: var(--heading-font);
      font-size: var(--xx-large-font-size);
    }
  }
}

/* text-group
---------------------------------------------- */
.text-group {
  &__heading {
    font-family: var(--heading-font);
    line-height: 1;
  }
}

/* text-group
---------------------------------------------- */
.writer {
  > *:not(:last-child) {
    margin-bottom: 1em;
  }
  ul {
    list-style: none;
    margin-left: .5em;
    li {
      &::marker {
        content: "• ";
        font-size: .9em; 
      }
    }
  }
}

/* three-column-gallery
---------------------------------------------- */
.three-column-gallery {
  overflow: clip;
  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--medium-grid-gap);
    margin-left: -10vw;
    margin-right: -10vw;
  }
  &__item {
    img {
      height: 45vw;
      object-fit: cover;
    }
  }
}

/* timeline
---------------------------------------------- */
.timeline {
  $self: &;
  background-color: var(--black);
  color: var(--white);
  padding: var(--small-vertical-margin) 0 var(--large-vertical-margin) 0;
  &__image-container {
    padding: 7vw;
  }
  .splide__slide {
    &.is-active {
      img {
        transform: scale(1.5);
      }
      #{ $self }__dot {
        height: 15px;
        width: 15px;
      }
    }
  }
  img {
    aspect-ratio: 1.2 / 1;
    object-fit: cover;
    transition: transform .6s ease-out;
    padding-top: 2rem;
  }
  &__dot-container {
    display: flex;
    flex-direction: row;
    margin-top: 4rem;
    align-items: center;
    height: 1.5rem;
  }
  &__dot-container::before,
  &__dot-container::after {
    content: '';
    height: .5px;
    width: 100%;
    background-color: var(--white);
    display: block;
  }
  &__dot {
    margin: 0 .4rem;
    background-color: var(--white);
    border-radius: 50%;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    transition: width .6s ease-out, height .6s ease-out;
  }
  &__year {
    text-align: center;
    margin-top: 2rem;
    font-family: var(--sans-serif-font);
    font-size: 1.1rem
  }
}
/* two-column-gallery
---------------------------------------------- */
.two-column-gallery {
  &__wrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--medium-grid-gap);
  }
  &__item {
    img {
      height: 45vw;
      object-fit: cover;
    }
  }
}
/* two-column-mix
---------------------------------------------- */
.two-column-mix {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  &__column-a {
    grid-column: 1 / span 6;
  }
  &__column-b {
    grid-column: 9 / span 4;
  }
  &__heading {
    font-size: var(--xxx-large-font-size);
    margin-bottom: var(--small-vertical-margin);
  }
  &__paragraph {
    font-size: var(--x-large-font-size);
  }
  &__image {
    margin-top: var(--x-large-vertical-margin);
  }

  .regions-list {
    columns: 2;
  }
  .region {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    &:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
    li:not(:last-of-type) {
      margin-bottom: .3rem;
    }
  }
  .region-title {
    margin-bottom: 1.5rem;
    font-family: var(--sans-serif-font);
    text-transform: uppercase;
  }
  .square-button {
    margin-top: var(--medium-vertical-margin);
    justify-content: initial;
  }
}
/* two-column-text-media
---------------------------------------------- */
.two-column-text-media {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  &__text {
    grid-column: 1 / span 6;
  }
  &__media {
    grid-column: 8 / span 5;
  }
  &--text-second {
    .two-column-text-media__text {
      grid-column: 7 / span 6;
    }
    .two-column-text-media__media {
      grid-column: 1 / span 5;
      order: -1;
    }
  }
  &__images {
    picture {
      display: block;
      &:not(:last-of-type) {
        margin-bottom: var(--x-small-vertical-margin);
      }
    }
    img {
      width: 100%;
      height: 20vw;
      object-fit: cover;
    }
  }
  .text-group__preheading {
    margin-bottom: var(--small-vertical-margin);
  }
  .text-group__heading {
    margin-bottom: var(--x-small-vertical-margin);
    font-size: var(--x-large-font-size);
  }
  .text-group__paragraph {
    &--large-font-size {
      font-size: var(--large-font-size);
    }
  }
}
/* two-column-text
---------------------------------------------- */
.two-column-text {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  >:first-child {
    grid-column: 1 / span 5;
  }
  >:last-child {
    grid-column: 7 / span 6;
  }
  .text-group__heading {
    font-size: var(--x-large-font-size);
    grid-column: span 5;
  }
  .text-group__paragraph p {
    font-size: var(--medium-font-size);
  }
  .preheading {
    margin-bottom: var(--xx-small-vertical-margin);
  }
}


/* legal-text
---------------------------------------------- */
.legal-text {
  max-width: var(--xx-large-width);
}