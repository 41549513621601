
/* CSS RESET
---------------------------------------------- */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
.main__headline h1,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1.18;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

/* 
* {
  box-sizing: border-box;
}
html, body {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
  word-break: break-word;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td {
  vertical-align: top;
}
h1, h2, h3, h4, h5 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-weight: normal;
  font-size: inherit
}
a {
  text-decoration: none;
  color: inherit;
  outline: none;
}
a:hover {
  outline: none;
}
a:focus,
button:focus {
  outline: none;
}
a:focus-visible{
  outline: 1px solid black;
  outline-offset: 1px;
}
a:focus-visible {
  background: none;
}
p {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  margin-bottom: 1em;
}
p:last-of-type {
  margin-bottom: 0rem;
}
figure {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  padding: 0;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
}
button:active {
  outline: none;
  border: none;
}
button:focus {
  outline: 0;
}
button:hover {
  cursor: pointer;
}
ol li:not(:last-of-type) {
  margin-bottom: 0rem;
}
ol, ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
li {
  display: block;
  text-align: -webkit-match-parent;
}
dd {
  margin-inline-start: 0px;
}
*:focus{
  outline: none;
}
input {
  border-radius: 0;
}
textarea, input[type=text], input[type=button], input[type="submit"], input[type=email], input[type=tel] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz--border-radius: 0;
  border-radius: 0;
  font-weight: inherit;
  font-family: inherit;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border: none;
  width: 100%;
  padding: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-block-start: 0em;
  padding-inline-start: 0em;
  padding-inline-end: 0em;
  padding-block-end: 0em;
  min-inline-size: min-content;
}
blockquote {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
*/

button {
  background: transparent;
  box-shadow: 0px 0px 0px transparent;
  border: 0px solid transparent;
  text-shadow: 0px 0px 0px transparent;
  padding: 0;
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
}
button:active {
  outline: none;
  border: none;
}
button:focus {
  outline: 0;
}
button:hover {
  cursor: pointer;
}